
<template>

  <div class="person-modify-nickname">

    <van-nav-bar title="修改昵称" left-arrow @click-left="onClickLeft" />

    <div class="main">

      <div class="modify-content">

        <div class="top">

          <div class="edit-name">

            <!-- <span class="tit">原昵称:</span> -->

            <div class="nickInput">
              <input type="text" maxlength="8" v-model="nick" id="nickInput" />
            </div>

            <div class="close" v-show="hasContent" @click="delNickName">
              <img src="@image/close.png" alt="" />
            </div>

          </div>

          <p class="notice">名称内不能包含空格，xxxxxx符号,字数在8个字以内</p>

        </div>

        <div class="sure" @click="saveChange">确定</div>

      </div>

    </div>

  </div>

</template>

<script>
import qs from "qs";
import { updateUserInfo } from "@api/user.js";
  export default {
    data() {
      return {
        nick : this.$store.state.profile.nickName ? this.$store.state.profile.nickName : this.$store.state.profile.mobile,
      };
    },
    methods: {
      onClickLeft() {
        this.$router.go(-1);
      },
      //删除昵称
      delNickName() {
        this.nick = "";
      },
      //保存修改
      saveChange() {
        const params = {
          nickName : this.nick
        }
        updateUserInfo(qs.stringify(params)).then(res=>{
          if(res.code == 0){
            this.$store.dispatch("GET_PROFILE");
            this.$toast("保存成功");
            this.$router.go(-1)
          }
        });
      },
    },
    computed: {
      hasContent() {
        if (this.nick) {
          return true;
        }
        return false;
      },
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep .van-nav-bar .van-icon {
    color: #333;
  }
  ::v-deep .van-nav-bar__title {
    font-size: 17px;
    font-weight: bold;
    color: #333333;
  }
  .person-modify-nickname {
    font-family: PingFang SC;
    .main {
      border-top: 11px solid #f4f4f4;
      padding: 0 30px;
      .modify-content {
        height: 280px;
        .top {
          .edit-name {
            position: relative;
            display: flex;
            align-items: center;
            height: 63px;
            border-bottom: 1px solid #eee;
            // .tit{
            //     font-size: 14px;
            //     font-weight: 500;
            //     color: #333333;
            // }
            .nickInput {
              position: relative;
              width: 200px;
              height: 19px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              flex: 1;
              #nickInput {
                width: 100%;
                padding: 0 25px 0 16px;
                font-size: 14px;
                font-weight: 500;
                color: #999999;
              }
            }
            .close {
              display: flex;
              align-items: center;
              img {
                width: 16px;
                height: 16px;
              }
            }
          }
          .notice {
            margin-top: 9.5px;
            font-size: 11px;
            font-weight: 400;
            color: #999999;
          }
        }
        .sure {
          width: 302.5px;
          height: 57px;
          margin: 130px auto;
          line-height: 57px;
          text-align: center;
          font-size: 17px;
          font-weight: 500;
          background: #4588ff;
          color: #fff;
          box-shadow: 0px 8px 8px 0px rgba(69, 136, 255, 0.33);
          border-radius: 49px;;
        }
      }
    }
  }
</style>